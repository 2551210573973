import {
  AccountCircleRounded,
  LocationOnRounded,
  WorkRounded,
} from "@material-ui/icons"
import { Link } from "gatsby"
import QRCode from "qrcode.react"
import React from "react"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Section from "../components/section"
import Seo from "../components/seo"

const About = () => (
  <Layout>
    <Seo title="About" />
    <Section>
      <Bio isAbout />
    </Section>
    <Section noPaddingTop>
      <h2>About</h2>
      <p className="iconText">
        <AccountCircleRounded fontSize="small" /> Fumitaka Kikukawa
      </p>
      <p className="iconText">
        <WorkRounded fontSize="small" /> Freelance
      </p>
      <p className="iconText">
        <LocationOnRounded fontSize="small" /> Tokyo, Japan
      </p>
      <h2>Education</h2>
      <ul>
        <li>
          Foundation of Engineering, Mar 2011, National Institute of Technology,
          Nara College, Japan.
        </li>
        <li>Bachelor of Engineering, Mar 2013, Wakayama University, Japan.</li>
        <li>Master of Engineering, Mar 2015, Wakayama University, Japan.</li>
      </ul>
      <h2>Work Experience</h2>
      <ul>
        <li>Apr. 2015 - Sep. 2016: Recruit Holdings Co., Ltd., Japan.</li>
        <li>Oct. 2016 - June 2021: giftee Inc., Japan.</li>
        <li>Oct. 2021 - Present: Freelance, Japan.</li>
      </ul>
      <h2>OSS Contribution</h2>
      <ul>
        <li>
          <a
            href={`https://github.com/voormedia/rails-erd`}
            target="_blank"
            rel="noopener noreferrer"
          >
            voormedia/rails-erd
          </a>
          <ul>
            <li>
              <a
                href={`https://github.com/voormedia/rails-erd/pull/252`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fix auto diagram generation #252
              </a>
            </li>
            <li>
              <a
                href={`https://github.com/voormedia/rails-erd/pull/288`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fix documentation for #283 #288
              </a>
            </li>
            <li>
              <a
                href={`https://github.com/voormedia/rails-erd/pull/339`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fix for adding require false option in Gemfile #339
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href={`https://github.com/Semantic-Org/Semantic-UI`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Semantic-Org/Semantic-UI
          </a>
          <ul>
            <li>
              <a
                href={`https://github.com/Semantic-Org/Semantic-UI/pull/6249`}
                target="_blank"
                rel="noopener noreferrer"
              >
                [Popup] Fix a white space property in tooltip #6249
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <h2>Link</h2>
      <ul>
        <li>
          <Link to="/">Blog</Link>
        </li>
        <li>
          <a
            href={`https://twitter.com/kikunantoka`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter (@kikunantoka)
          </a>
        </li>
        <li>
          <a
            href={`https://github.com/kikunantoka`}
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub (kikunantoka)
          </a>
        </li>
        <li>
          <a
            href={`https://speakerdeck.com/kikunantoka`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Speaker Deck (id:kikunantoka)
          </a>
        </li>
        <li>
          <a
            href={`https://qiita.com/kikunantoka`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Qiita (@kikunantoka)
          </a>
        </li>
      </ul>
      <h2>QRCode</h2>
      <QRCode value="https://kikunantoka.com/about" />
      <h2>Credit</h2>
      <p>
        <a
          href="https://github.com/twitter/twemoji"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twemoji
        </a>{" "}
        graphics licensed under CC-BY 4.0.
      </p>
    </Section>
  </Layout>
)

export default About
